<template>
  <div class="main">
    <div class="itemBox">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>简历管理</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <el-form
        ref="form"
        label-position="left"
        :model="form"
        label-width="100px"
        :required-asterisk="true"
        :rules="formRules"
      >
        <!-- 基本信息 -->
        <div class="essentialInformation">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-jibenxinxi"
                style="font-size: 22px; color: #fff"
              ></i>
            </div>
            <b>基本信息</b>
          </div>
          <div class="content">
            <div class="upPhoto">
              <el-form-item prop="avatar">
                <img v-if="imgUrl" class="photo" :src="imgUrl" alt="" />
                <img
                  v-else
                  class="photo"
                  src="@/assets/personCenter/resumeManagement/null.png"
                  alt=""
                />
                <el-upload
                  name="uploadfile"
                  class="avatar-uploader"
                  :action="$global.uploadAction"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                <el-button plain>更换头像</el-button>
                </el-upload>
                <div class="tipBox">建议头像尺寸：最好正方形 200X200 或 300 X 300 宽。<br> 注:<br>
                (1) 上传图片只能是JPG格式或PNG格式,且图片大小不能超过5MB; <br>(2) 上传图片时请检查图片原有格式是否是JPG/PNG格式!</div>
              </el-form-item>
            </div>
            <div class="center">
              <el-form-item prop="name" label="姓名：">
                <el-input v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item prop="wasBornDate" label="出生年月：">
                <el-date-picker
                  type="date"
                  align="center"
                  placeholder="选择时间"
                  v-model="form.wasBornDate"
                  style="width: 100%"
                  :picker-options="pickerOptions"
                  @change="getWasBornDate"
                ></el-date-picker>
              </el-form-item>
              <el-form-item prop="nation" label="民族：">
                <el-input v-model="form.nation" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item prop="school" label="毕业院校：">
                <el-input v-model="form.school" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item prop="tel" label="联系方式：">
                <el-input v-model="form.tel" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item prop="workingState" label="在职状态：">
                <el-select
                  v-model="form.workingState"
                  placeholder="请选择"
                  @change="getWorkStatus"
                >
                  <el-option
                    v-for="(item, index) in workingState"
                    :key="index"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item prop="sex" label="性别：">
                <el-select
                  v-model="form.sex"
                  placeholder="请选择性别"
                  @change="getSex"
                >
                  <el-option
                    v-for="(item, index) in sex"
                    :key="index"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="age" label="年龄：">
                <el-input v-model="form.age" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item prop="edu" label="学历：">
                <el-select v-model="form.edu" placeholder="请选择学历">
                  <el-option
                    v-for="(item, index) in jobEdu"
                    :key="index"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="finishSchoolTime" label="毕业时间：">
                <el-date-picker
                  type="date"
                  align="center"
                  placeholder="选择时间"
                  v-model="form.finishSchoolTime"
                  style="width: 100%"
                  :picker-options="pickerOptions"
                  @change="getGradutionTime"
                ></el-date-picker>
              </el-form-item>
              <el-form-item prop="email" label="邮箱：">
                <el-input v-model="form.email" placeholder="请输入"></el-input>
              </el-form-item>

              <el-form-item prop="exp" label="工作经验：">
                <el-select
                  v-model="form.exp"
                  placeholder="请选择"
                  @change="getExp"
                >
                  <el-option
                    v-for="item in exp"
                    :key="item.name"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>

        <!-- 求职意向 -->
        <div class="jobSearchIntention">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-lingdaitie2"
                style="font-size: 22px; color: #fff"
              ></i>
            </div>
            <b>求职意向</b>
          </div>
          <div class="content">
            <div class="item">
              <span class="formLabel"> <span style="color: red; ">*</span> 求职类型</span>
              <el-form-item prop="applyJobType">
                <el-radio-group v-model="form.applyJobType">
                  <el-radio-button label="全职"></el-radio-button>
                  <el-radio-button label="兼职"></el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="item">
              <span class="formLabel"><span style="color: red; ">*</span>工作城市</span>
              <el-form-item prop="city">
                <!-- 这里判断用于回显，如果懒加载数据比回显数据先出来会回显不上去 -->
                <el-cascader
                  v-if="form.city && isResume"
                  v-model="form.city"
                  :key="derKey"
                  :props="cityProps"
                  @change="handleChange"
                  :show-all-levels="false"
                  placeholder="请选择城市"
                ></el-cascader>
                <el-cascader
                  v-else
                  v-model="form.city"
                  :props="cityProps"
                  :key="derKey"
                  @change="handleChange"
                  :show-all-levels="false"
                  placeholder="请选择城市"
                ></el-cascader>
              </el-form-item>
            </div>
            <div class="item">
              <span class="formLabel"><span style="color: red; ">*</span>期望行业</span>
              <el-form-item prop="expectIndustry">
                <el-cascader
                  v-model="form.expectIndustry"
                  :options="tradeTypeList"
                  :show-all-levels="false"
                  :props="typeProps"
                  @change="chooseTradeType"
                  placeholder="期望行业"
                ></el-cascader>
              </el-form-item>
            </div>
            <div class="item">
              <span class="formLabel"><span style="color: red; ">*</span>期望职位</span>
              <el-form-item prop="expectPost">
                <el-cascader
                  v-model="form.expectPost"
                  :options="postTypeList"
                  :show-all-levels="false"
                  :props="typeProps2"
                  @change="choosePostType"
                  placeholder="期望职位"
                ></el-cascader>
              </el-form-item>
            </div>
            <div class="item">
              <span class="formLabel"><span style="color: red; ">*</span>期望薪资</span>
              <div class="salary">
                <el-form-item prop="expectSalary">
                  <el-select
                    v-model="form.expectSalary"
                    @change="getSalary"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in jobPay"
                      :key="item.name"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </el-form>

      <!-- 工作经历 -->
      <el-form
        ref="workExperienceForm"
        label-position="left"
        :model="workExperienceForm"
        label-width="100px"
      >
        <div class="workExperience">
          <div class="title addFormTitle">
            <div class="blueBgc">
              <i
                class="iconfont icon-gongjuxiang"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>工作经历</b>
            <div class="addForm" @click="addWorkExperienceForm">
              <div>
                <i class="el-icon-plus"></i>
              </div>
              <span>添加</span>
            </div>
          </div>
          <div
            class="content"
            v-for="(workExperienceFormItem, index) in workExperienceForm.works"
            :key="index"
          >
            <div class="centerDel" v-if="index != 0">
              <div class="del" @click="deleteItem(1, index)">
                <i
                  class="el-icon-error"
                  style="font-size: 24px; color: #999"
                ></i>
                <span>删除</span>
              </div>
            </div>
            <div class="item">
              <span class="formLabel">公司名称</span>
              <el-form-item prop="title">
                <el-input
                  v-model="workExperienceFormItem.title"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <span class="formLabel">职位名称</span>
              <el-form-item prop="postName">
                <el-input
                  v-model="workExperienceFormItem.postName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <span class="formLabel">所属行业</span>
              <el-form-item prop="industry">
                <el-cascader
                  v-model="workExperienceFormItem.industry"
                  :options="tradeTypeList"
                  :show-all-levels="false"
                  :props="typeProps"
                  @change="chooseTndustry($event, index)"
                  placeholder="公司行业"
                ></el-cascader>
              </el-form-item>
            </div>
            <div class="item skill">
              <span class="formLabel">拥有技能</span>
              <el-form-item prop="skill">
                <el-input
                  v-model="workExperienceFormItem.skill"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item jobTime">
              <span class="formLabel">在职时间</span>
              <div class="onTtTheJobTime">
                <el-form-item prop="btime">
                  <el-date-picker
                    type="date"
                    align="center"
                    placeholder="选择时间"
                    v-model="workExperienceFormItem.btime"
                    style="width: 100%"
                    :picker-options="pickerOptions"
                    @change="getworkExperienceFormItemBtime($event, index)"
                  ></el-date-picker>
                </el-form-item>
                <div class="zhi">至</div>
                <el-form-item prop="ntime">
                  <el-date-picker
                    type="date"
                    align="center"
                    placeholder="选择时间"
                    v-model="workExperienceFormItem.ntime"
                    style="width: 100%"
                    :picker-options="pickerOptions"
                    @change="getworkExperienceFormItemNtime($event, index)"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="item jobContent">
              <span class="formLabel">工作内容</span>
              <el-form-item prop="content">
                <el-input
                  v-model="workExperienceFormItem.content"
                  placeholder="描述你的职责范围、工作任务以及取得的成绩"
                  type="textarea"
                  :autosize="{ minRows: 6 }"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>

      <!-- 项目经历 -->
      <el-form
        ref="projectExperienceForm"
        label-position="left"
        :model="projectExperienceForm"
        label-width="100px"
      >
        <div class="projectExperience">
          <div class="title addFormTitle">
            <div class="blueBgc">
              <i
                class="iconfont icon-xiangmujingli-02"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>项目经历</b>
            <div class="addForm" @click="addProjectExperienceForm">
              <div>
                <i class="el-icon-plus"></i>
              </div>
              <span>添加</span>
            </div>
          </div>
          <div
            class="content"
            v-for="(
              projectExperienceItem, index
            ) in projectExperienceForm.projects"
            :key="index"
          >
            <div class="centerDel" v-if="index != 0">
              <div class="del" @click="deleteItem(3, index)">
                <i
                  class="el-icon-error"
                  style="font-size: 24px; color: #999"
                ></i>
                <span>删除</span>
              </div>
            </div>
            <div class="item">
              <span class="formLabel">项目名称</span>
              <el-form-item prop="title">
                <el-input
                  v-model="projectExperienceItem.title"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item jobTime">
              <span class="formLabel">项目时间</span>
              <div class="onTtTheJobTime">
                <el-form-item prop="btime">
                  <el-date-picker
                    type="date"
                    align="center"
                    placeholder="选择时间"
                    v-model="projectExperienceItem.btime"
                    style="width: 100%"
                    :picker-options="pickerOptions"
                    @change="getProjectExperienceFormItemBtime($event, index)"
                  ></el-date-picker>
                </el-form-item>
                <div class="zhi">至</div>
                <el-form-item prop="ntime">
                  <el-date-picker
                    type="date"
                    align="center"
                    placeholder="选择时间"
                    v-model="projectExperienceItem.ntime"
                    style="width: 100%"
                    :picker-options="pickerOptions"
                    @change="getProjectExperienceFormItemNtime($event, index)"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="item">
              <span class="formLabel">所属公司</span>
              <el-form-item prop="specialty">
                <el-input
                  v-model="projectExperienceItem.specialty"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item jobContent">
              <span class="formLabel">工作内容</span>
              <el-form-item prop="content">
                <el-input
                  v-model="projectExperienceItem.content"
                  placeholder="描述你的职责范围、工作任务以及取得的成绩"
                  type="textarea"
                  :autosize="{ minRows: 6 }"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>

      <!-- 教育经历 -->
      <el-form
        ref="educationalExperienceForm"
        label-position="left"
        :model="educationalExperienceForm"
        label-width="100px"
      >
        <div class="educationalExperience">
          <div class="title addFormTitle">
            <div class="blueBgc">
              <i
                class="iconfont icon-jiaoyu1"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>教育经历</b>
            <div class="addForm" @click="addEducationalExperienceForm">
              <div>
                <i class="el-icon-plus"></i>
              </div>
              <span>添加</span>
            </div>
          </div>
          <div
            class="content"
            v-for="(
              educationalExperienceFormItem, index
            ) in educationalExperienceForm.educationals"
            :key="index"
          >
            <div class="centerDel" v-if="index != 0">
              <div class="del" @click="deleteItem(2, index)">
                <i
                  class="el-icon-error"
                  style="font-size: 24px; color: #999"
                ></i>
                <span>删除</span>
              </div>
            </div>
            <div class="item">
              <span class="formLabel">学校名称</span>
              <el-form-item prop="title">
                <el-input
                  v-model="educationalExperienceFormItem.title"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <span class="formLabel">学历</span>
              <el-form-item prop="edu">
                <el-input
                  v-model="educationalExperienceFormItem.edu"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <span class="formLabel">专业</span>
              <el-form-item prop="specialty">
                <el-input
                  v-model="educationalExperienceFormItem.specialty"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item schoolTime">
              <span class="formLabel">在校时间</span>
              <div class="onTtTheSchoolTime">
                <el-form-item prop="btime">
                  <el-date-picker
                    type="date"
                    align="center"
                    placeholder="选择时间"
                    v-model="educationalExperienceFormItem.btime"
                    style="width: 100%"
                    :picker-options="pickerOptions"
                    @change="
                      getEducationalExperienceFormItemBtime($event, index)
                    "
                  ></el-date-picker>
                </el-form-item>
                <div class="zhi">至</div>
                <el-form-item prop="ntime">
                  <el-date-picker
                    type="date"
                    align="center"
                    placeholder="选择时间"
                    v-model="educationalExperienceFormItem.ntime"
                    style="width: 100%"
                    :picker-options="pickerOptions"
                    @change="
                      getEducationalExperienceFormItemNtime($event, index)
                    "
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="item schoolContent">
              <span class="formLabel">在校经历</span>
              <el-form-item prop="content">
                <el-input
                  v-model="educationalExperienceFormItem.content"
                  placeholder="描述你的职责范围、工作任务以及取得的成绩"
                  type="textarea"
                  :autosize="{ minRows: 6 }"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>

      <!-- 培训经历 -->
      <el-form
        ref="trainingExperienceForm"
        label-position="left"
        :model="trainingExperienceForm"
        label-width="100px"
      >
        <div class="trainingExperience">
          <div class="title addFormTitle">
            <div class="blueBgc">
              <i
                class="iconfont icon-peixunke"
                style="font-size: 24px; color: #fff"
              ></i>
            </div>
            <b>培训经历</b>
            <div class="addForm" @click="addTrainingExperienceForm">
              <div>
                <i class="el-icon-plus"></i>
              </div>
              <span>添加</span>
            </div>
          </div>
          <div
            class="content"
            v-for="(
              trainingExperienceFormItem, index
            ) in trainingExperienceForm.trains"
            :key="index"
          >
            <div class="centerDel" v-if="index != 0">
              <div class="del" @click="deleteItem(4, index)">
                <i
                  class="el-icon-error"
                  style="font-size: 24px; color: #999"
                ></i>
                <span>删除</span>
              </div>
            </div>
            <div class="item">
              <span class="formLabel">培训机构</span>
              <el-form-item prop="title">
                <el-input
                  v-model="trainingExperienceFormItem.title"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item schoolTime">
              <span class="formLabel">培训时间</span>
              <div class="onTtTheSchoolTime">
                <el-form-item prop="btime">
                  <el-date-picker
                    type="date"
                    align="center"
                    placeholder="选择时间"
                    v-model="trainingExperienceFormItem.btime"
                    :picker-options="pickerOptions"
                    @change="getTrainingExperienceFormItemBtime($event, index)"
                  ></el-date-picker>
                </el-form-item>
                <div class="zhi">至</div>
                <el-form-item prop="ntime">
                  <el-date-picker
                    type="date"
                    align="center"
                    placeholder="选择时间"
                    v-model="trainingExperienceFormItem.ntime"
                    :picker-options="pickerOptions"
                    @change="getTrainingExperienceFormItemNtime($event, index)"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="item schoolContent">
              <span class="formLabel">培训描述</span>
              <el-form-item prop="content">
                <el-input
                  v-model="trainingExperienceFormItem.content"
                  placeholder="描述你所学的主要培训内容"
                  type="textarea"
                  :autosize="{ minRows: 6 }"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>

      <!-- 自我评价 -->
      <el-form
        ref="selfEvaluationForm"
        label-position="left"
        :model="selfEvaluationForm"
        label-width="100px"
        :rules="selfEvaluationFormRules"
      >
        <div class="selfEvaluation">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-jieshao"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b><span style="color: red; ">*</span>自我评价</b>
          </div>
          <div class="content">
            <el-form-item prop="myIntro">
              <el-input
                v-model="selfEvaluationForm.myIntro"
                placeholder="介绍自己说明自己的最大优势，让企业看到自己的闪光点"
                type="textarea"
                :autosize="{ minRows: 6 }"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="skillCertificate" v-if="jobUserType == 2 && certificates">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-jibenxinxi"
              style="font-size: 22px; color: #fff"
            ></i>
          </div>
          <b>技能证书</b>
        </div>
        <div class="content">
          <div class="imgBox">
            <span>学历证书</span>
            <img
              v-if="certificates.degree_certificate"
              :src="$imgUrl(certificates.degree_certificate)"
              :onerror="$global.srcImgUrl"
              alt=""
            />
            <img v-else src="@/assets/img/null.png" alt="" />
          </div>
          <div class="imgBox">
            <span>资格证书</span>
            <img
              v-if="certificates.qualification_certificate"
              :src="$imgUrl(certificates.qualification_certificate)"
              :onerror="$global.srcImgUrl"
              alt=""
            />
            <img v-else src="@/assets/img/null.png" alt="" />
          </div>
          <div class="imgBox">
            <span>职称证书</span>
            <img
              v-if="certificates.title_certificate"
              :src="$imgUrl(certificates.title_certificate)"
              :onerror="$global.srcImgUrl"
              alt=""
            />
            <img v-else src="@/assets/img/null.png" alt="" />
          </div>
        </div>
      </div>
      <div class="btnBox">
        <el-button type="primary" :loading="saveFlag" @click="save"
          >保存</el-button
        >
        <el-button type="info" @click="dialogVisible = true">取消</el-button>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>退出后简历将不会保存，是否退出？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="goOutResume">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getLoginUser } from '@/api/login/login'
import { preview } from '@/api/certification/certification'
import { jobPostType } from '@/api/position/position'
import {
  addResume,
  detailResume,
  editResume,
} from '@/api/personalCenter/personalCenter'
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
import { getProvincial, getCity } from '@/api/index/index'
import moment from 'moment'
import { queryExists } from '@/api/jobsensitivechar/jobSensitiveCharManage'
import genTestUserSig from "@/utils/debug/GenerateTestUserSig.js";
export default {
  name: '',
  data() {
    const validateTel = (rule, value, callback) => {
      let regTest =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      console.log(value)
      if (!value) {
        callback(new Error('请输入联系方式'))
      } else {
        if (!regTest.test(value)) {
          callback(new Error('手机号码格式不正确'))
          return
        }
        callback()
      }
    }

    const validateEmail = (rule, value, callback) => {
      let regTest = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else {
        if (!regTest.test(value)) {
          callback(new Error('邮箱格式不正确'))
          return
        }
        callback()
      }
    }
    return {
      derKey: 0,
      imgPath: '',
      saveFlag: false, //保存按钮，避免多次保存
      dialogVisible: false,
      dict: [], //字典
      sex: [],
      jobEdu: [], //学历
      jobPay: [], //薪资
      workingState: [], //在职状态
      postTypeList: [], //职位类型列表
      tradeTypeList: [], //行业类型列表
      userInfo: {}, //个人信息
      typeProps: {
        value: 'id',
        label: 'title',
        children: 'children',
      },
      typeProps2: {
        value: 'title',
        label: 'title',
        children: 'children',
        // multiple: true
      },
      form: {
        specialty: '', //1是管理人才简历，2是技术人才简历
        jobUserId: '',
        avatar: '', //头像
        name: '', //姓名
        wasBornDate: '', //出生年月
        nation: '', //民族
        school: '', //毕业院校
        tel: '', //联系方式
        sex: '', //性别
        age: '', //年龄
        edu: '', //学历
        finishSchoolTime: '', //毕业时间
        email: '', //邮箱
        applyJobType: '', //求职类型
        city: '', //工作城市
        expectIndustry: '', //期望行业
        expectPost: '', //期望职位
        expectSalary: '', //期望薪资
        workingState: '', //在职状态
        exp: '', //工作经验
      },
      formRules: {
        // avatar: [{ required: true, message: '请上传照片' }],
        name: [{ required: true, message: '请输入姓名' }],
        wasBornDate: [
          { type: 'string', required: true, message: '请选择日期' },
        ],
        nation: [{ required: true, message: '请输入民族' }],
        school: [{ required: true, message: '请输入毕业院校' }],
        tel: [{ required: true, validator: validateTel }],
        sex: [{ required: true, message: '请选择性别' }],
        age: [{ required: true, message: '请输入年龄' }],
        edu: [{ required: true, message: '请选择学历' }],
        finishSchoolTime: [{ required: true, message: '请选择毕业时间' }],
        email: [{ required: true, validator: validateEmail }],
        applyJobType: [{ required: true, message: '请选择求职类型' }],
        city: [{ required: true, message: '请选择工作城市' }],
        expectIndustry: [{ required: true, message: '请选择期望行业' }],
        expectPost: [{ required: true, message: '请选择期望职位' }],
        expectSalary: [{ required: true, message: '请选择期望薪资' }],
        workingState: [{ required: true, message: '请选择在职状态' }],
        exp: [{ required: true, message: '请选择工作经验' }],
      },
      // 工作经历
      workExperienceForm: {
        works: [
          {
            itemType: 1,
            title: '', //公司名称
            postName: '', //职位名称
            industry: '', //所属行业
            skill: '', //拥有技能
            btime: null, //在职时间
            ntime: null, //在职时间
            content: '', //工作内容
          },
        ],
      },

      // 项目经历
      projectExperienceForm: {
        projects: [
          {
            itemType: 3,
            title: '', //项目名称
            specialty: '', //所属公司
            btime: null, //项目开始时间
            ntime: null, //项目结束时间
            content: '', //工作内容
          },
        ],
      },
      // 培训经历
      trainingExperienceForm: {
        trains: [
          {
            itemType: 4,
            title: '', //培训机构名称
            btime: null, //培训开始时间
            ntime: null, //培训结束时间
            content: '', //培训描述
          },
        ],
      },
      // 教育经历
      educationalExperienceForm: {
        educationals: [
          {
            itemType: 2,
            title: '', //学校名称
            edu: '', //学历
            specialty: '', //专业
            btime: null,
            ntime: null,
            content: '',
          },
        ],
      },
      // 自我评价
      selfEvaluationForm: {
        myIntro: '',
      },
      selfEvaluationFormRules: {
        myIntro: [{ required: true, message: '请填写自我评价' }],
      },
      imgUrl: '',
      provincialList: [], //省份列表
      cityProps: {},
      // cityProps: {
      //   value: 'pid',
      //   label: 'Provincial',
      //   children: 'children',
      // },
      // provincial: '', //选择省份
      city: '', //选择城市
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      fromFlag: false, //from表单验证
      selfEvaluationFormFlag: false, //自我评价表单验证
      isResume: false, //是否添加过简历
      jobUserType: '', //简历类型
      certificates: {}, //证书信息
      cityList: [], //城市列表
      text01: '',
      boo: false
    }
  },
  watch: {},
  props: {},
  components: {},
  computed: {},
  created() {
    this.jobUserType = this.$route.query.jobUserType
    if (this.jobUserType) {
      this.getJobUserAuthForId()
    }
    this.getDetailResume()
    this.getJobPostType()
    this.getJobTradeType()
    this.cityProps = {
      value: 'PROVINCE_CODE',
      label: 'SHORT_NAME',
      children: 'children',
      lazy: true,
      lazyLoad: (node, resolve) => {
        const { level } = node
        if (level == 0) {
          getProvincial().then(res => {
            const city = res.data
            resolve(city)
          })
        }
        if (level == 1) {
          getCity({ PROVINCE_CODE: node.value }).then(res => {
            let arr = []
            res.data.forEach(i => {
              const obj = {
                PROVINCE_CODE: i.CITY_CODE,
                SHORT_NAME: i.SHORT_NAME,
                leaf: true,
              }
              arr.push(obj)
            })
            const city = arr
            resolve(city)
          })
        }
      },
    }
    this.form.jobUserId = localStorage.getItem('userId')
    this.dict = JSON.parse(localStorage.getItem('dict'))
    this.sex = this.dict.filter(i => i.code == 'sex')[0].children
    // this.sex.splice(this.sex.length - 1, 1)
    this.jobEdu = this.dict.filter(i => i.code == 'job_edu')[0].children
    this.jobPay = this.dict.filter(i => i.code == 'job_pay')[0].children
    this.workingState = this.dict.filter(
      i => i.code == 'job_resume_working_state'
    )[0].children
    this.workingState.splice(this.workingState.length - 1, 1)
    this.exp = this.dict.filter(i => i.code == 'job_exp')[0].children
    this.cityList = JSON.parse(localStorage.getItem('cityList'))
    // this.getProvincialList()
    // this.getJobUserAuthForId()
  },
  mounted() {},
  methods: {
    // 个人信息
    getUserInfo() {
      getLoginUser().then(res => {
        this.userInfo = res.data
        this.form.name = this.userInfo.name ? this.userInfo.name : ''
        this.form.tel = this.userInfo.phone ? this.userInfo.phone : ''
        this.form.email = this.userInfo.email ? this.userInfo.email : ''
        if (this.userInfo.sex && this.userInfo.sex == 1) {
          this.form.sex = '男'
        } else if (this.userInfo.sex && this.userInfo.sex == 2) {
          this.form.sex = '女'
        }
      })
    },
    // 查询认证信息
    getJobUserAuthForId() {
      getJobUserAuth().then(res => {
        if (res.data) {
          this.certificates = res.data
          this.form.name = this.certificates.name
          this.form.sex = this.certificates.sex
          let wasBornDate = this.certificates.identity_code.substring(6, 14)
          this.form.wasBornDate = moment(wasBornDate).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          this.form.age =
            moment(new Date()).format('YYYY') -
            moment(wasBornDate).format('YYYY')
        }
      })
    },
    // 证书
    getCertificates() {
      getJobUserAuth().then(res => {
        if (res.data) {
          this.certificates = res.data
        }
      })
    },
    // 获取简历
    getDetailResume() {
      let id = localStorage.getItem('userId')
      detailResume({ JobUserId: id }).then(res => {
        // this.resume = res.data
        console.log(res, '查询简历')
        if (res.success && res.data) {
          this.isResume = true
          let resume = res.data
          this.form.id = resume.id
          this.form.jobUserId = resume.jobUserId
          this.form.avatar = resume.avatar
          this.form.name = resume.name
          this.form.wasBornDate = resume.wasBornDate
          this.form.nation = resume.nation
          this.form.school = resume.school
          this.form.tel = resume.tel
          this.form.workingState = resume.workingState
          this.form.sex = resume.sex == 1 ? '男' : '女'
          this.form.age = resume.age
          this.form.edu = resume.edu
          this.form.finishSchoolTime = resume.finishSchoolTime
          this.form.email = resume.email
          this.form.exp = resume.exp
          this.form.specialty = resume.specialty
          this.form.jobUserType = resume.jobUserType
          this.jobUserType = resume.jobUserType
          this.form.applyJobType = resume.applyJobType
          let city = this.cityList.filter(i => i.CITY_CODE == resume.city)[0]
            .PROVINCE_CODE
          this.form.city = [city, resume.city]
          this.city = [city, resume.city]
          this.form.expectIndustry = resume.expectIndustry
          this.form.expectPost = resume.expectPost
          this.form.expectSalary = resume.expectSalary
          this.derKey++
          let workExperience = []
          let educationalExperience = []
          let projectExperience = []
          let trainingExperience = []
          resume.items.forEach(item => {
            if (item.itemType == 1) {
              workExperience.push(item)
            } else if (item.itemType == 2) {
              educationalExperience.push(item)
            } else if (item.itemType == 3) {
              projectExperience.push(item)
            } else if (item.itemType == 4) {
              trainingExperience.push(item)
            }
          })
          this.workExperienceForm.works = workExperience
          this.educationalExperienceForm.educationals = educationalExperience
          this.projectExperienceForm.projects = projectExperience
          this.trainingExperienceForm.trains = trainingExperience
          this.selfEvaluationForm.myIntro = resume.myIntro

          // preview({ id: resume.avatar }).then(res => {
          //   let blob = new Blob([res]) // 返回的文件流数据
          //   let url = window.URL.createObjectURL(blob) // 将他转化为路径
          //   this.imgUrl = url
          //   console.log(this.imgUrl)
          // })
          this.imgUrl = resume.avatar
          this.isResume = false
          setTimeout(() => {
              this.isResume = true
          }, 500)
          this.getCertificates()
        } else {
          // 如果没有填写过简历查询个人信息
          // this.getUserInfo()

          // 如果没有填写过简历查询认证信息
          this.getJobUserAuthForId()
        }
      })
    },
    getExp(e) {
      console.log(e)
      this.form.Exp = e
    },
    getWorkStatus(e) {
      console.log(e)
      this.form.workingState = e
    },
    getSex(e) {
      console.log(e)
      console.log(this.form)
    },
    // 级联选择器递归判断children有没有值
    hasChildren(list) {
      list.forEach(item => {
        if (item.children.length > 0) {
          this.hasChildren(item.children)
        } else {
          item.children = null
        }
      })
      return list
    },
    // 职位类型
    getJobPostType() {
      jobPostType({ code: 'post_type' }).then(res => {
        this.postTypeList = this.hasChildren(res.data[0].children)
      })
    },
    //行业类型
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then(res => {
        this.tradeTypeList = this.hasChildren(res.data[0].children)
      })
    },
    deleteItem(num, index) {
      switch (num) {
        case 1:
          this.workExperienceForm.works.splice(index, 1)
          break
        case 2:
          this.educationalExperienceForm.educationals.splice(index, 1)
          break
        case 3:
          this.projectExperienceForm.projects.splice(index, 1)
          break
        case 4:
          this.trainingExperienceForm.trains.splice(index, 1)
        default:
          break
      }
    },
    // 添加工作经历表单
    addWorkExperienceForm() {
      let obj = {
        itemType: 1,
        title: '', //公司名称
        postName: '', //职位名称
        industry: '', //所属行业
        skill: '', //拥有技能
        btime: null, //在职时间
        ntime: null, //在职时间
        content: '', //工作内容
      }
      this.workExperienceForm.works.push(obj)
    },
    // 添加项目经历表单
    addProjectExperienceForm() {
      let obj = {
        itemType: 3,
        title: '', //项目名称
        specialty: '', //所属公司
        btime: null, //项目开始时间
        ntime: null, //项目结束时间
        content: '', //工作内容
      }
      this.projectExperienceForm.projects.push(obj)
    },
    // 添加教育经历表单
    addEducationalExperienceForm() {
      let obj = {
        itemType: 2,
        title: '', //学校名称
        edu: '', //学历
        specialty: '', //专业
        btime: null,
        ntime: null,
        content: '',
      }
      this.educationalExperienceForm.educationals.push(obj)
    },
    // 添加培训经历
    addTrainingExperienceForm() {
      let obj = {
        itemType: 4,
        title: '', //培训机构名称
        btime: null, //培训开始时间
        ntime: null, //培训结束时间
        content: '', //培训描述
      }
      this.trainingExperienceForm.trains.push(obj)
    },
    getworkExperienceFormItemBtime(val, index) {
      this.workExperienceForm.works[index].btime = moment(val).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    getworkExperienceFormItemNtime(val, index) {
      this.workExperienceForm.works[index].ntime = moment(val).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    getProjectExperienceFormItemBtime(val, index) {
      this.projectExperienceForm.projects[index].btime = moment(val).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    getProjectExperienceFormItemNtime(val, index) {
      this.projectExperienceForm.projects[index].ntime = moment(val).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    getEducationalExperienceFormItemBtime(val, index) {
      this.educationalExperienceForm.educationals[index].btime = moment(
        val
      ).format('YYYY-MM-DD HH:mm:ss')
    },
    getEducationalExperienceFormItemNtime(val, index) {
      this.educationalExperienceForm.educationals[index].ntime = moment(
        val
      ).format('YYYY-MM-DD HH:mm:ss')
    },
    getTrainingExperienceFormItemBtime(val, index) {
      this.trainingExperienceForm.trains[index].btime = moment(val).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    getTrainingExperienceFormItemNtime(val, index) {
      this.trainingExperienceForm.trains[index].ntime = moment(val).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    getWasBornDate(val) {
      this.form.wasBornDate = moment(val).format('YYYY-MM-DD HH:mm:ss')
      this.form.age =
        moment(new Date()).format('YYYY') - moment(val).format('YYYY')
    },
    getGradutionTime(val) {
      this.form.finishSchoolTime = moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    getSalary(val) {
      console.log(val, '薪资')
      this.form.expectSalary = val
    },
    handleChange(val) {
      console.log(val)
      // this.form.city = val[val.length - 1]
      this.city = val
    },
    // 获取职位
    choosePostType(val) {
      console.log(val)
      this.form.expectPost = val[val.length - 1]
    },
    // 获取期望行业
    chooseTradeType(e) {
      console.log(e)
      this.form.expectIndustry = e[e.length - 1]
    },
    // 公司行业
    chooseTndustry(e, index) {
      console.log(e[e.length - 1])
      this.workExperienceForm.works[index].industry = e[e.length - 1]
    },
    // 保存简历
    save() {
      this.saveFlag = true
      // 简历类型 jobUserType -> specialty  1是管理，2是技术

      // 基本信息验证
      this.$refs.form.validate(valid => {
        if (valid) {
          this.fromFlag = true
        } else {
          this.saveFlag = false
          console.log('error 基本信息验证!!')
          return false
        }
      })
      // 自我评价验证
      this.$refs.selfEvaluationForm.validate(valid => {
        if (valid) {
          this.selfEvaluationFormFlag = true
        } else {
          this.saveFlag = false
          console.log('error 自我评价验证!!')
          return false
        }
      })
      let obj = this.form
      // obj.sex = obj.sex == '男' ? 1 : 2
      // if (obj.sex == '男') {
      //   obj.sex = 1
      // } else if (obj.sex == '女') {
      //   obj.sex = 2
      // } else if (obj.sex == 1) {
      //   obj.sex = 1
      // } else if (obj.sex == 2) {
      //   obj.sex = 2
      // }
      obj.myIntro = this.selfEvaluationForm.myIntro
      obj.jobUserType = this.jobUserType
      obj.specialty = this.jobUserType

      // obj.city = JSON.stringify(obj.city)
      obj.items = []
      obj.items = [
        ...this.workExperienceForm.works,
        ...this.projectExperienceForm.projects,
        ...this.trainingExperienceForm.trains,
        ...this.educationalExperienceForm.educationals,
      ]
      console.log(this.fromFlag, this.selfEvaluationFormFlag, this.isResume)
      this.queryExists(obj).then(() => {
          if (this.boo) {
            if (
              this.fromFlag &&
              this.selfEvaluationFormFlag &&
              this.isResume == false
            ) {
              console.log(obj, '新增简历')
              // obj.city = obj.city[obj.city.length - 1]
              obj.city = this.city[1]
              // auditStatus
              addResume(obj).then(res => {
                // this.saveFlag = true
                console.log(res, '上传简历')
                if (res.success) {
                  this.timCreate(obj.name)
                  this.$message({
                    message: '添加成功',
                    type: 'success',
                  })
                  // this.$refs.form.resetFields()
                  // this.$refs.workExperienceForm.resetFields()
                  // this.$refs.projectExperienceForm.resetFields()
                  // this.$refs.trainingExperienceForm.resetFields()
                  // this.$refs.educationalExperienceForm.resetFields()
                  // this.$refs.selfEvaluationForm.resetFields()
                  setTimeout(() => {
                    this.saveFlag = false
                    this.$router.push('resumeManagement')
                  }, 500)
                } else {
                  this.saveFlag = false
                  this.$message.error('添加失败')
                }
              })
            } else if (this.isResume) {
              console.log(obj, '编辑简历')
              obj.sex = obj.sex == '男' ? 1 : 2
              obj.city = obj.city[obj.city.length - 1]
              obj.city = this.city[1]
              editResume(obj).then(res => {
                console.log(res, '编辑简历')
                // this.saveFlag = true
                if (res.success) {
                  this.timCreate(obj.name)
                  this.$message({
                    message: '编辑成功',
                    type: 'success',
                  })
                  // this.$refs.form.resetFields()
                  // this.$refs.workExperienceForm.resetFields()
                  // this.$refs.projectExperienceForm.resetFields()
                  // this.$refs.trainingExperienceForm.resetFields()
                  // this.$refs.educationalExperienceForm.resetFields()
                  // this.$refs.selfEvaluationForm.resetFields()
                  setTimeout(() => {
                    this.saveFlag = false
                    this.$router.push('resumeManagement')
                  }, 500)
                } else {
                  this.saveFlag = false
                  this.$message.error('添加失败')
                }
              })
            }
          } else {
             this.saveFlag = false
          }
      })

    },
    timCreate(name){
       localStorage.setItem("account",name);
      const userID = localStorage.getItem('userId');
      const userSig = genTestUserSig(userID).userSig;
      let promise = this.$tim.login({ userID: userID, userSig: userSig });
      promise
        .then((res) => {
          console.log(res, "修改名称成功");
        })
        .catch((err) => {
          console.log(err, "修改名称失败");
        });
    },
    // 取消编辑简历
    goOutResume() {
      this.$router.push('resumeManagement')
    },
    handleAvatarSuccess(res, file) {
      console.log(res, '图片')
      // this.imageUrl = URL.createObjectURL(file.raw)
      this.form.avatar = file.response.msg
      this.$message({
        type: 'success',
        message: '上传成功',
        showClose: true,
        offset: 80,
      })
      // preview({ id: res.data }).then(res => {
      //   let blob = new Blob([res]) // 返回的文件流数据
      //   let url = window.URL.createObjectURL(blob) // 将他转化为路径
      //   this.imgUrl = url
      //   console.log(this.imgUrl)
      // })
      this.imgUrl = file.response.msg
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 .jpg .png 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
      async queryExists(record) {
        console.log('record', record)
        const newText = JSON.stringify(record)
        record = {}
        record.newText = newText
        this.text01 = ''
        this.boo = false
        await queryExists(record).then((res) => {
          if (res.success) {
            console.log(res.data)
            if (res.data.status) {
              if (Object.keys(res.data.map).length > 0) {
                // let i = 0
                for (const key in res.data.map) {
                  this.text01 = this.text01 + key + ' : ' + res.data.map[key] + '， '
                }
                this.text01 = this.text01.substring(0, this.text01.length - 1)
                this.boo = false
                console.log(this.text01 + 'text01')
                 this.$message.close()
                this.$message.warning('文章中敏感词汇如下：  ' + this.text01)
                // this.visible = true
              } else {
                this.boo = true
                this.$message.close()
                // this.$message.success('文章中无敏感词')
              }
            } else {
              this.boo = false
              this.$message.close()
              this.$message.error('敏感字符过滤失败')
            }
          } else {
            this.$message.close()
            this.$message.error('敏感字符过滤失败') // + res.message
            this.boo = false
          }
        })
      }
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 20px;
  ::v-deep .el-form-item__content {
    width: 60%;
    .el-input__inner {
      height: 32px;
    }
  }
  .el-input__inner {
    height: 32px;
  }
}

.tipBox {
  width: 160px;
  font-size: 12px;
  line-height: 18px;
  color: #ccc;
}

.itemName {
  padding: 0 46px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.itemBox {
  background-color: #fff;
  padding: 20px 40px;
}

.essentialInformation,
.jobSearchIntention,
.workExperience,
.projectExperience,
.trainingExperience,
.educationalExperience,
.selfEvaluation,
.skillCertificate {
  margin-bottom: 50px;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .blueBgc {
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background-color: #3f9efc;
      border-radius: 50%;
      margin-right: 20px;
    }
    // img {
    //   width: 36px;
    //   height: 36px;
    //   margin-right: 20px;
    // }
    b {
      font-size: 20px;
    }
  }
  .content {
    margin-left: 14px;
    .item {
      .formLabel {
        display: inline-block;
        margin-bottom: 16px;
      }
    }
  }
}

// 基本信息
.essentialInformation {
  .content {
    display: flex;
    justify-content: space-between;

    .upPhoto {
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      .photo {
        width: 160px;
        height: 160px;
        object-fit: cover;
      }
      .avatar-uploader {
        width: 160px;
        text-align: center;
        // margin-top: 20px;
        .el-button {
          width: 80px;
          height: 32px;
          padding: 0;
        }
      }
    }
  }
}

// 求职意向
.jobSearchIntention {
  .content {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 33.33%;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
        width: 80%;
        .el-radio-button__inner {
          border-left: 1 solid #dcdfe6;
        }
      }
      .salary {
        display: flex;
        width: 80%;
        ::v-deep .el-form-item__content {
          width: 100%;
        }
        //     .zhi {
        //       padding: 0 6px;
        //       margin-bottom: 20px;
        // line-height: 40px;
        //     }
      }
      .city {
        display: flex;
        align-items: center;
        ::v-deep .provincial {
          flex: 1;
          .el-input {
            width: 80%;
          }
          .el-input__inner {
            height: 32px;
          }
          /* 下面设置右侧按钮居中 */
          .el-input__suffix {
            top: 5px;
          }
          .el-input__icon {
            line-height: inherit;
          }
          .el-input__suffix-inner {
            display: inline-block;
          }
        }
        .el-form-item {
          flex: 1;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// 工作经历
.workExperience {
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .centerDel {
      width: 100%;
      .del {
        color: #999;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        i {
          margin-right: 10px;
        }
      }
    }
    .item {
      width: 33.33%;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
        width: 80%;
        .el-radio-button__inner {
          border-left: 1 solid #dcdfe6;
        }
      }
    }
    .skill {
      width: 63%;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
        width: 95%;
        .el-radio-button__inner {
          border-left: 1 solid #dcdfe6;
        }
      }
    }
    .jobTime {
      width: 33%;
      .onTtTheJobTime {
        display: flex;
        // width: 90%;
        ::v-deep .el-form-item__content {
          width: 100%;
        }
        .zhi {
          padding: 0 6px;
          margin-bottom: 20px;
          line-height: 40px;
        }
      }
    }
    .jobContent {
      width: 100%;
      ::v-deep .el-form-item__content {
        width: 96%;
      }
    }
  }
  .content:nth-last-child(1) {
    margin-bottom: 0;
  }
}

// 项目经历
.projectExperience {
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .centerDel {
      width: 100%;
      .del {
        color: #999;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        i {
          margin-right: 10px;
        }
      }
    }
    .item {
      width: 30%;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
        width: 80%;
        .el-radio-button__inner {
          border-left: 1 solid #dcdfe6;
        }
      }
    }
    .skill {
      width: 63%;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
        width: 95%;
        .el-radio-button__inner {
          border-left: 1 solid #dcdfe6;
        }
      }
    }
    .jobTime {
      width: 40%;
      .onTtTheJobTime {
        padding-right: 40px;
        display: flex;
        // width: 90%;
        ::v-deep .el-form-item__content {
          width: 100%;
        }
        .zhi {
          padding: 0 6px;
          margin-bottom: 20px;
          line-height: 40px;
        }
      }
    }
    .jobContent {
      width: 100%;
      ::v-deep .el-form-item__content {
        width: 96%;
      }
    }
  }
  .content:nth-last-child(1) {
    margin-bottom: 0;
  }
}

// 教育经历
.educationalExperience {
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .centerDel {
      width: 100%;
      .del {
        color: #999;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        i {
          margin-right: 10px;
        }
      }
    }
    .item {
      width: 33.33%;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
        width: 80%;
      }
    }
    .schoolTime {
      width: 70%;
      .onTtTheSchoolTime {
        display: flex;
        width: 100%;
        ::v-deep .el-form-item__content {
          width: 100%;
        }
        .zhi {
          padding: 0 20px;
          margin-bottom: 20px;
          line-height: 40px;
        }
      }
    }
    .schoolContent {
      width: 100%;
      ::v-deep .el-form-item__content {
        width: 96%;
      }
    }
  }
  .content:nth-last-child(1) {
    margin-bottom: 0;
  }
}

// 培训经历
.trainingExperience {
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .centerDel {
      width: 100%;
      .del {
        color: #999;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        i {
          margin-right: 10px;
        }
      }
    }
    .item {
      width: 33.33%;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
        width: 80%;
      }
    }
    .schoolTime {
      // width: 70%;
      .onTtTheSchoolTime {
        display: flex;
        width: 100%;
        ::v-deep .el-form-item__content {
          width: 100%;
        }
        .zhi {
          padding: 0 20px;
          margin-bottom: 20px;
          line-height: 40px;
        }
      }
    }
    .schoolContent {
      width: 100%;
      ::v-deep .el-form-item__content {
        width: 96%;
      }
    }
  }
  .content:nth-last-child(1) {
    margin-bottom: 0;
  }
}

// 自我评价
.selfEvaluation {
  .content {
    // ::v-deep .el-form-item__content {
    //   margin-left: 0 !important;
    //   width: 96%;
    // }
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
      width: 96%;
    }
  }
}

// 技能证书
.skillCertificate {
  .content {
    display: flex;
    flex-wrap: wrap;
    .imgBox {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 30px;
      span {
        font-size: 14px;
        margin-bottom: 10px;
      }
      img {
        width: 218px;
        height: 138px;
        object-fit: contain;
      }
    }
  }
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  .el-button {
    width: 83px;
    height: 42px;
    border-radius: 2px;
    margin: 0 60px;
  }
}

.addFormTitle {
  position: relative;
  .addForm {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    div {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      border-radius: 50%;
      background-color: #3f9efc;
      i {
        color: #fff;
      }
    }
    span {
      color: #3f9efc;
      margin-left: 10px;
    }
  }
}
</style>
