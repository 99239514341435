import request from "@/api/request"

/**
 * 查询敏感字符
 *
 * @author cc
 * @date 2022-09-19 17:58:49
 */
export function jobSensitiveCharPage (parameter) {
  return request({
    url: '/main/jobSensitiveChar/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 敏感字符列表
 *
 * @author cc
 * @date 2022-09-19 17:58:49
 */
export function jobSensitiveCharList (parameter) {
  return request({
    url: '/main/jobSensitiveChar/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 添加敏感字符
 *
 * @author cc
 * @date 2022-09-19 17:58:49
 */
export function jobSensitiveCharAdd (parameter) {
  return request({
    url: '/main/jobSensitiveChar/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 编辑敏感字符
 *
 * @author cc
 * @date 2022-09-19 17:58:49
 */
export function jobSensitiveCharEdit (parameter) {
  return request({
    url: '/main/jobSensitiveChar/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 删除敏感字符
 *
 * @author cc
 * @date 2022-09-19 17:58:49
 */
export function jobSensitiveCharDelete (parameter) {
  return request({
    url: '/main/jobSensitiveChar/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 导出敏感字符
 *
 * @author cc
 * @date 2022-09-19 17:58:49
 */
export function jobSensitiveCharExport (parameter) {
  return request({
    url: '/main/jobSensitiveChar/export',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}

/**
 * 筛选敏感字符
 *
 * @author cc
 * @date 2022-09-19 17:58:49
 */
 export function queryExists (parameter) {
  return request({
    url: '/main/jobSensitiveChar/queryExists',
    method: 'post',
    data: parameter
  })
}
